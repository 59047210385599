<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>
            {{ title }}

            <v-spacer />

            <v-btn small depressed color="info" @click="add">
              {{ $t('actions.add') }}
            </v-btn>
          </v-card-title>
          <v-card-subtitle style="min-height: 40px;">
            <template v-if="isEditting && object.pk">
              {{ $t('users.warnings.password') }}
            </template>
          </v-card-subtitle>

          <v-window v-model="currentWindow">
            <v-window-item :key="0">
              <v-card-text>
                <v-text-field
                  v-model="search"
                  solo
                  prepend-inner-icon="mdi-magnify"
                  :label="$t('helpers.search')"
                  hide-details
                />

                <v-data-table
                  :items="users"
                  :headers="headers"
                  :search="search"
                  :items-per-page.sync="itemsPerPage"
                >
                  <template #body="{ items }">
                    <tr v-for="item in items" :key="item.pk">
                      <td>{{ item.pk }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.email }}</td>
                      <td class="text-right pa-2">
                        <v-btn
                          small
                          icon
                          color="info"
                          @click="show(item.pk)"
                        >
                          <v-icon small>mdi-eye-outline</v-icon>
                        </v-btn>

                        <v-btn
                          small
                          icon
                          color="warning"
                          @click="edit(item.pk)"
                        >
                          <v-icon small>mdi-pencil-outline</v-icon>
                        </v-btn>

                        <v-btn
                          :disabled="isLoading"
                          :loading="isLoading"
                          small
                          icon
                          color="error"
                          @click="del(item.pk)"
                        >
                          <v-icon small>mdi-delete-outline</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-window-item>

            <v-window-item :key="1">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="object.name"
                      :label="$t('users.name')"
                      :readonly="!isEditting"
                      required
                      filled
                      :error="hasErrors('name')"
                      :error-messages="getErrors('name')"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="object.email"
                      :label="$t('users.email')"
                      :readonly="!isEditting"
                      required
                      filled
                      type="email"
                      :error="hasErrors('email')"
                      :error-messages="getErrors('email')"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-autocomplete
                      v-model="object.destinationId"
                      :items="destinations"
                      :label="$t('customers.destinationId')"
                      :readonly="!isEditting"
                      required
                      filled
                      item-text="name"
                      item-value="pk"
                      :error="hasErrors('destinationId')"
                      :error-messages="getErrors('destinationId')"
                    />
                  </v-col>

                  <v-col v-show="isEditting" cols="12">
                    <v-text-field
                      v-model="object.password"
                      :label="$t('users.password')"
                      :readonly="!isEditting"
                      required
                      filled
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      :error="hasErrors('password')"
                      :error-messages="getErrors('password')"
                      @click:append="showPassword = !showPassword"
                    />
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  v-if="isEditting"
                  small
                  :disabled="isLoading"
                  :loading="isLoading"
                  depressed
                  color="error"
                  @click="discard"
                >
                  {{ $t('actions.discard') }}
                </v-btn>

                <v-btn
                  v-else
                  small
                  :disabled="isLoading"
                  :loading="isLoading"
                  depressed
                  color="info"
                  @click="discard"
                >
                  {{ $t('actions.back') }}
                </v-btn>

                <v-spacer />

                <v-btn
                  v-if="isEditting"
                  small
                  :disabled="isLoading"
                  :loading="isLoading"
                  depressed
                  color="success"
                  @click="save"
                >
                  {{ $t('actions.save') }}
                </v-btn>

                <template v-else>
                  <v-btn
                    small
                    :disabled="isLoading"
                    :loading="isLoading"
                    depressed
                    color="error"
                    @click="del(object.pk)"
                  >
                    {{ $t('actions.delete') }}
                  </v-btn>

                  <v-btn
                    small
                    :disabled="isLoading"
                    :loading="isLoading"
                    depressed
                    color="warning"
                    @click="edit(object.pk)"
                  >
                    {{ $t('actions.edit') }}
                  </v-btn>
                </template>
              </v-card-actions>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { orm } from '@/mixins'
export default {
  mixins: [orm],
  data () {
    return {
      /*
      Meaning:
        - 0: Listing
        - 1: Edit/Show
      */
      currentWindow: 0,

      /* States */
      isEditting: false,
      isLoading: false,

      /* Entity */
      errors: {},
      object: {},
      showPassword: false,

      /* Table */
      search: ''
    }
  },

  computed: {
    session () { return this.$store.state.session },

    title () {
      if (this.currentWindow === 0) { return this.$t('customers.title.list') }
      
      if (this.object?.pk) {
        if (this.isEditting) { return this.$t('customers.title.edit', { name: this.object?.name }) }

        return this.$t('customers.title.show', { name: this.object?.name })
      }
      return this.$t('customers.title.add')
    },

    itemsPerPage: {
      get () { return this.$store.state.itemsPerPage },
      set (value) { this.$store.commit('setItemsPerPage', value) }
    },

    destinations () { return this.$store.state.destinations.list },

    headers () {
      return [
        { text: this.$t('users.pk'), value: 'pk' },
        { text: this.$t('users.name'), value: 'name' },
        { text: this.$t('users.email'), value: 'email' },
        { text: '', sortable: false }
      ]
    },

    users () {
      return this.$store.getters['users/customers']
    },

    defaultObject () {
      return {
        pk: null,
        name: '',
        email: '',
        password: '',
        destinationId: null,
        level: 'USER'
      }
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
  },

  methods: {
    async edit (pk) {
      await this.show(pk, true)
    },

    async show (pk, isEditting) {
      await this.$store.commit('setLoading', true)
      this.isEditting = isEditting
      const obj = await this.$store.dispatch('users/details', pk)

      if (obj) {
        this.object = Object.assign({}, this.defaultObject, obj)
      }
      this.currentWindow = 1
      await this.$store.commit('setLoading', false)
    },

    async del (pk) {
      this.isLoading = true
      const result = await this.$store.dispatch('users/delete', pk)
      
      if (result) {
        this.$store.commit('toggleSnackbar', {
          message: this.$t('customers.title.delete'),
          color: 'green darken-2'
        })
      }
      this.isLoading = false
    },

    add () {
      this.currentWindow = 1
      this.isEditting = true
      this.object = Object.assign({}, this.defaultObject)
    },

    discard () {
      this.currentWindow = 0
      this.isEditting = false
      this.object = Object.assign({}, this.defaultObject)
    },

    async save () {
      this.isLoading = true
      this.errors = {}

      const obj = {
        pk: this.object.pk,
        name: this.object.name,
        email: this.object.email,
        password: this.object.password,
        level: this.object.level,
        destinationId: this.object.destinationId
      }

      let response = null
      if (!obj.pk) {
        response = await this.$store.dispatch('users/add', obj)
      } else {
        response = await this.$store.dispatch('users/edit', obj)
      }

      const { status, errors, result } = response

      switch (status) {
        case 'OK':
          this.isEditting = false
          this.object = Object.assign({}, result)
          break
        case 'BADREQUEST':
          this.errors = errors
          break
        default:
          this.discard()
      }

      this.isLoading = false
    }
  }
}
</script>