<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>{{ $t('plants.title.list') }}</v-card-title>

          <v-card-text>
            <v-text-field
              v-model="search"
              solo
              prepend-inner-icon="mdi-magnify"
              :label="$t('helpers.search')"
              hide-details
            />

            <v-data-table
              :items="plants"
              :headers="headers"
              :search="search"
              :items-per-page.sync="itemsPerPage"
            >
              <template #body="{ items }">
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td>{{ item.pk }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.code }}</td>
                    <td>{{ item.geofence }}</td>
                    <td class="text-right pa-2">
                      <v-btn
                        small
                        icon
                        color="info"
                        @click="assign(item.pk)"
                      >
                        <v-icon small>mdi-link</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="assignDialog"
      width="80%"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t('plants.title.assign') }}</v-card-title>

        <v-card-text>
          <v-autocomplete
            v-model="geofencePk"
            :items="geofences"
            item-text="name"
            item-value="pk"
            :label="$t('plants.geofence')"
            hide-details
            solo
          />
        </v-card-text>

        <v-card-actions>
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            color="error"
            text
            @click="dismiss"
          >
            {{ $t('actions.cancel') }}
          </v-btn>

          <v-spacer />

          <v-btn
            :disabled="isLoading || !geofencePk"
            :loading="isLoading"
            color="success"
            text
            @click="confirm"
          >
            {{ $t('actions.assign') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      isLoading: false,
      assignDialog: false,
      geofencePk: null,
      itemPk: null,
      search: ''
    }
  },

  computed: {
    headers () {
      return [
        { text: this.$t('plants.pk'), value: 'pk' },
        { text: this.$t('plants.name'), value: 'name' },
        { text: this.$t('plants.code'), value: 'code' },
        { text: this.$t('plants.geofence'), value: 'geofence' },
        { text: '' }
      ]
    },

    itemsPerPage: {
      get () { return this.$store.state.itemsPerPage },
      set (value) { this.$store.commit('setItemsPerPage', value) }
    },

    geofences () { return this.$store.state.geofences.list },

    plants () {
      return this.$store.state.plants.list.map(plant => {
        return {
          pk: plant.pk,
          name: plant.name,
          code: plant.code,
          geofence: plant.geofence?.name || this.$t('helpers.notAvailable')
        }
      })
    }
  },

  mounted () {
    const href = window.location.href.split('?')
    
    let args = new URLSearchParams()
    if (href.length > 1) {
      args = new URLSearchParams(href[1])
    }

    if (args.get('filter') === 'notLinked') {
      this.search = this.$t('helpers.notAvailable')
    }
  },

  methods: {
    assign (pk) {
      this.itemPk = pk
      this.geofencePk = null
      this.assignDialog = true
    },

    dismiss () {
      this.assignDialog = false
      this.itemPk = null
      this.geofencePk = null
    },

    async confirm () {
      this.isLoading = true
      const result = await this.$store.dispatch('plants/assign', {
        pk: this.itemPk,
        geofencePk: this.geofencePk
      })
      this.isLoading = false

      if (result) {
        this.$store.commit('toggleSnackbar', {
          message: this.$t('plants.assignSuccess'),
          color: 'green darken-2'
        })
        this.dismiss()
      }
    }
  }
}
</script>