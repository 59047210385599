<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>{{ $t('units.title.list') }}</v-card-title>
          <v-card-subtitle>{{ $t('wialon.caption') }}</v-card-subtitle>

          <v-card-text>
            <v-text-field
              v-model="search"
              solo
              prepend-inner-icon="mdi-magnify"
              :label="$t('helpers.search')"
              hide-details
            />

            <v-data-table
              :items="units"
              :headers="headers"
              :search="search"
              :items-per-page.sync="itemsPerPage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      search: ''
    }
  },

  computed: {
    headers () {
      return [
        { text: this.$t('units.pk'), value: 'pk' },
        { text: this.$t('units.plate'), value: 'plate' },
        { text: this.$t('units.ident'), value: 'ident' },
        { text: this.$t('units.carrier'), value: 'carrier' }
      ]
    },

    itemsPerPage: {
      get () { return this.$store.state.itemsPerPage },
      set (value) { this.$store.commit('setItemsPerPage', value) }
    },

    units () {
      return this.$store.state.units.list.map(item => {
        return {
          pk: item.pk,
          plate: item.plate,
          ident: item.ident,
          carrier: item.carrier?.name || this.$t('helpers.notAvailable')
        }
      })
    }
  }
}
</script>