<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>{{ $t('drivers.title.list') }}</v-card-title>

          <v-card-text>
            <v-text-field
              v-model="search"
              solo
              prepend-inner-icon="mdi-magnify"
              :label="$t('helpers.search')"
              hide-details
            />

            <v-data-table
              :items="users"
              :headers="headers"
              :search="search"
              :items-per-page.sync="itemsPerPage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      search: ''
    }
  },

  computed: {
    headers () {
      return [
        { text: this.$t('drivers.pk'), value: 'pk' },
        { text: this.$t('drivers.name'), value: 'name' },
        { text: this.$t('drivers.code'), value: 'code' }
      ]
    },

    itemsPerPage: {
      get () { return this.$store.state.itemsPerPage },
      set (value) { this.$store.commit('setItemsPerPage', value) }
    },

    users () {
      return this.$store.state.drivers.list
    }
  }
}
</script>