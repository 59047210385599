<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-card-title>{{ $t('geofences.title.list') }}</v-card-title>
          <v-card-subtitle>{{ $t('wialon.caption') }}</v-card-subtitle>

          <v-card-text>
            <v-text-field
              v-model="search"
              solo
              prepend-inner-icon="mdi-magnify"
              :label="$t('helpers.search')"
              hide-details
            />

            <v-data-table
              :items="units"
              :headers="headers"
              :search="search"
              :items-per-page.sync="itemsPerPage"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      search: ''
    }
  },

  computed: {
    headers () {
      return [
        { text: this.$t('geofences.pk'), value: 'pk' },
        { text: this.$t('geofences.name'), value: 'name' },
        { text: this.$t('geofences.mode'), value: 'mode' }
      ]
    },

    itemsPerPage: {
      get () { return this.$store.state.itemsPerPage },
      set (value) { this.$store.commit('setItemsPerPage', value) }
    },

    units () {
      return this.$store.state.geofences.list.map(item => {
        return {
          pk: item.pk,
          name: item.name,
          mode: this.$t(`geofences.modes.${item.mode}`)
        }
      })
    }
  }
}
</script>